<template>
  <div class="angebot">


    <!-- Das hier sollte eigentlich gehen, Masterlösung hat aber irgend ein Ladeproblem -->

    <p v-if="!clicked" class="url hover:underline" name="button" @click="click()">Erkunden Sie unser Angebot</p>

    <ol>

      <div v-if="clicked" class="text-lg" v-for="index in angebotdata">

        <li> {{index}} <!-- <i v-if="hausarzt[index].length > 0" class="far fa-plus-square"></i> --> </li>


        <div v-if="" class="" v-for="exdex in hausarzt[index]">

          <router-link class="url-lg hover:underline" tag="p" :to="'praxis/' + exdex.url" > {{ exdex.name}} {{ exdex.lastname}} </router-link tag="div" to="praxis" >

          </div>

        </div>

      </ol>

      <p v-if="clicked" class="url hover:underline" @click="click()">weniger anzeigen</p>

    </div>

  </template>

  <script>
  export default {
    name: 'Angebot',
    props: {

    },
    data() {
      return {
        data: [],
        showmenu: false,
        meetus: false,
        offer: false,
        data: [],
        accordeon: [],
        doctors: [],
        datadoctor: [],
        assistenz: [],
        lernende: [],
        loaded: false,
        angebote: [],
        // alle angebote müssen hier aufgelistet werden. Ebenso in Wordpress --> ACF --> Feldgruppe_Ärzte --> Angebot
        angebotdata: ['Akupunktur', 'Belastungs-EKG', '24h-Blutdruck-Messung', 'Diabetesberatung', 'EKG', 'Ernährungsberatung', 'Impfberatungen', 'Laboranalysen', 'Lungenfunktionsmessung', 'Lymphdrainage', 'Physiotherapie', 'Psychotherapie', 'Reisemedizinische Beratungen', 'Röntgenuntersuchungen', 'Schlaf-Apnoe-Abklärung', 'Schulärztliche Untersuchungen', 'Sportmedizinische Untersuchungen', 'Strassenverkehrsmedizinische Untersuchungen', 'Ultraschall', 'Vorsorgeuntersuchungen' ],
        view: [],
        hausarzt: [],
        clicked: false,
      }
    },
    methods: {

      click(){
        this.clicked = !this.clicked;

        if(!this.clicked){
          window.scrollTo(0, 100);
        }

      },

      getDoctors(index){

        // console.log("getting da docs! " + index)

        this.hausarzt[index] = [];


        async function getMitarbeiterAsync(index)
        {
          let response = await fetch('https://ärztezentrum.ch/api/wp-json/acf/v3/mitarbeitende?filter[meta_key]=angebot&filter[meta_compare]=LIKE&filter[meta_value]=' + index);
          let data = await response.json()
          return data;
        }

        getMitarbeiterAsync(index)
        .then((data) => {

          var i;
          for (i in data) {

            // console.log(data[i].acf)

            this.hausarzt[index].push(data[i].acf);
            // console.log(data[i].acf);

          }

          this.view[index] = true;

        });





      },

      Angebot(index){

        this.angebotdata.forEach(this.getData);

      },

      getData(index){

        this.hausarzt[index] = []

        async function getMitarbeiterAsync(index)
        {
          let response = await fetch('https://ärztezentrum.ch/api/wp-json/acf/v3/mitarbeitende?filter[meta_key]=angebot&filter[meta_compare]=LIKE&filter[meta_value]=' + index);
          let data = await response.json()
          return data;
        }

        getMitarbeiterAsync(index)
        .then((data) => {

          var i;
          for (i in data) {

            // console.log(data[i].acf)

            this.hausarzt[index].push(data[i].acf);
            // console.log(data[i].acf);

          }

          this.view = true;
          // console.log("done");

        });


      },

      fetchContent(){

        fetch('https://ärztezentrum.ch/api/wp-json/acf/v3/angebot')  .then((result) => {
          return result.json();
        }).then((data) => {

          var i;
          for (i in data) {
            this.angebote.push(data[i].acf);
            // console.log("Länge " + data.length);

            var j;
            for (j in this.angebote[i].anbieter) {

              // console.log(this.angebote[i].anbieter[j].ID);

              // richtige namen kommen, richtige i & j (im console log ebenfalls), aber es wird nichts im view angezeigt.


              fetch('https://ärztezentrum.ch/api/wp-json/acf/v3/mitarbeitende?filter[p]=' + this.angebote[i].anbieter[j].ID )  .then((result) => {
                return result.json();
              }).then((data) => {


                this.datadoctor = data[0].acf;

                // console.log(this.datadoctor.name);



                // this.angebote[i].anbieter[j].ID = data[0].acf.name;
                // this.angebote[i].anbieter[j].myname = data[0].acf.name;

                // console.log(this.angebote[i].anbieter[j].ID);
                // console.log(data[0].acf.name);

              });

              // const request = async () => {
              //   const response = await fetch('https://ärztezentrum.ch/api/wp-json/acf/v3/mitarbeitende?filter[p]=' + this.angebote[i].anbieter[j].ID);
              //   const json = await response.json();
              //   // console.log(json[0].acf.name)
              //   this.datadoctor = json[0].acf;
              // }
              //
              // request();

              // console.log(i);
              // console.log(j);
              // console.log(this.datadoctor.name);

              this.angebote[i].anbieter[j].myname = this.datadoctor.name

            }

          }

        });

      },

      fetchdoctor(){

        var k;
        for (k in this.doctors) {

          // hole arzt mit ID [p]
          fetch('https://ärztezentrum.ch/api/wp-json/acf/v3/mitarbeitende?filter[p]=' + this.doctors[k] )  .then((result) => {
            return result.json();
          }).then((data) => {

            this.datadoctor.push(data[0].acf);

            // console.log(this.datadoctor[0].portrait.url);
            this.loaded = true;


          });

        }

      },


    },

    mounted: function(){
      // this.fetchContent();
      // this.manualContent();
      this.Angebot();
    },

    computed: {

      sortedData() {
        // console.log(this.data.slice().sort(this.compare));
        return "hello";
      },

    },
  }

  </script>

  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>


  </style>
