<template>
  <div class="cookie">


    <div class="">


      <div v-if="!cookie" class="sm:flex fixed bottom-0 left-0 w-full items-center bg-blue-500 text-white text-sm font-bold px-4 py-3" role="alert">

        <p>Wir verwenden Cookies, um die Nutzung unserer Website zu analysieren.</p>

        <div class="">

          <button class="sm:ml-4 color-blue text-lg hover:underline" type="button" name="button" @click="setcookie()"> Okay </button>

          <router-link to="/impressum" tag="button" class="ml-4 color-blue text-lg hover:underline" type="button" name="button"> Mehr lesen </router-link>

        </div>

      </div>

    </div>

  </div>

</template>

<script>
import Angebot from '@/components/Angebot.vue'

export default {
  name: 'Cookie',
  components: {
    Angebot
  },
  props: {

  },
  data() {
    return {
      data: [],
      showmenu: false,
      meetus: false,
      offer: false,
      cookie: false,
    }
  },
  methods: {

    getcookie(){

      var guetzli = localStorage.getItem("cookie");
      // console.log("Z guezli isch " + guetzli);
      // console.log(this.cookie);

      if (guetzli){
        this.cookie = true;
      }

    },

    setcookie(){
      this.cookie = true;

      localStorage.setItem("cookie", true);
      // console.log(localStorage.getItem("cookie"));

    },

  },

  mounted: function(){
    this.getcookie();
  },

  computed: {

  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
