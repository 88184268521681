<template>
  <MglMap class="h-screen w-1/2" :accessToken="accessToken" :mapStyle="mapStyle" />
</template>

<script>
import Mapbox from "mapbox-gl";
import { MglMap } from "vue-mapbox";

export default {
  components: {
    MglMap
  },
  name: 'Map',
  data() {
    return {
      accessToken: 'pk.eyJ1IjoiZ290b3BpayIsImEiOiJjanBjZW02YzYxMnFoM3FtODU5Zm5kemZ2In0.VGy4GCIQJZYd96EwLVn9kQ', // your access token. Needed if you using Mapbox maps
      mapStyle: 'mapbox://styles/mapbox/streets-v11', // your map style
      zoom: 15,
      center: [46, 7]
    };
  },

  created() {
    // We need to set mapbox-gl library here in order to use it in template
    this.mapbox = Mapbox;
  }
};
</script>
