<template>
  <div class="home flex flex-col h-full bg-white h-full">

    <Notfall class="z-50" v-if="$store.state.notfall"> </Notfall>

    <div class="body w-full mx-auto bg-white">

      <!-- <Map> </Map> -->

      <Navigation> </Navigation>

      <Meldungen> </Meldungen>

      <!-- <img class="mb-8" src="../assets/header.png" alt=""> -->

      <!-- <h1 class="font-body text-4xl mt-6">Übersicht</h1> -->

      <Overview class="w-full lg:w-3/4 mx-auto"> </Overview>

      <GridPraxen class="mx-auto w-full lg:w-3/4"> </GridPraxen>

      <Cookie> </Cookie>

      <FooterVue class='w-full text-center p-4 pin-b'> </FooterVue>

      <div @click="notfall()" class="fixed bottom-0 right-0 mx-2 sm:mx-8 mb-2 sm:mb-8 btn text-white bg-red-600 cursor-pointer hover:bg-red-700">
        Notfall
      </div>

    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import GridPraxen from '@/components/GridPraxen.vue'
import Navigation from '@/components/Navigation.vue'
import Overview from '@/components/Overview.vue'
import Map from '@/components/Map.vue'
import FooterVue from '@/components/FooterVue.vue'
import Notfall from '@/components/Notfall.vue'
import Cookie from '@/components/Cookie.vue'
import Meldungen from '@/components/Meldungen.vue'

export default {
  name: 'home',
  components: {
    GridPraxen,
    Navigation,
    Overview,
    Map,
    FooterVue,
    Notfall,
    Cookie,
    Meldungen
  },
  methods: {
    notfall(){

      this.$store.commit('notfall');

    }
  }
}
</script>

<style scoped>

.modal {
  z-index: 88;
}

</style>
