<template>

  <!-- eslint-disable  -->

  <div class="w-full lg:w-5/6 text-left mx-auto" v-if="this.meldungloaded">

    <div v-bind:class="{ 'bg-orange-300': this.meldung.typ == 'Warnung',  'bg-red-300': this.meldung.typ == 'Gefahr', 'bg-blue-300': this.meldung.typ == 'Information'}" class="mx-8 my-4 p-4 rounded shadow-md">

    <h2 class="font-bold mb-0 text-black">

    {{ this.meldung.titel }}</h2>

    <p class="text-black" v-html="this.meldung.meldung"></p>

  </div>


  <!-- <div class="mx-8 my-4 p-4 rounded shadow-md bg-orange-300">

  <h2 class="font-bold mb-0">
  <i v-if="index.typ == 'Warnung' || index.typ == 'Gefahr'" class="fas fa-exclamation-triangle"></i>
  <i v-else class="fas fa-info-circle"></i>

  {{ index.titel }}</h2>

  <p class="text-black"> {{ index.meldung }}</p>

</div>-->

</div>


</template>

<script>

export default {
  components: {

  },
  name: 'Meldungen',
  props: {

  },
  data() {
    return {

      actualYear: '',
      actualDate: '',
      data: '',
      meldungloaded: false,

    }
  },
  methods: {

    convert(old){

      // console.log("nodedate:" + old);

      var monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni",
      "Juli", "August", "September", "Oktober", "November", "Dezember"];

      // console.log(old)

      var yyyy = old.slice(0, 4)
      // console.log("Actual:" + yyyy);
      // console.log("This:" + this.actualYear)

      var mm = old.slice(4, 6)
      mm = parseInt(mm)

      var dd = old.slice(6, 8)

      if (yyyy == this.actualYear){
        return (dd + ". " + monthNames[mm-1] + " " + yyyy);
      } else {
        return (dd + ". " + monthNames[mm-1] + " " + yyyy);
      }
    },

    getdate(){
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0');
      var yyyy = today.getFullYear();

      // console.log(today = dd + '. ' + monthNames[mm] + ' ' + yyyy);

      this.actualYear = yyyy;
      this.actualDate = yyyy + mm + dd

      // console.log("DATUM IST" + this.actualDate);
    },

    fetchmeldungen(){

      fetch('https://ärztezentrum.ch/api/wp-json/acf/v3/meldungen')  .then((result) => {
        return result.json();
      }).then((data) => {

        this.meldung = data[0].acf;

        this.meldungloaded = true;

        console.log(this.meldung);

      });



    },

  },
  mounted: function(){

    this.getdate();
    this.fetchmeldungen();

  },

  computed: {

    sortMeldungen() {
      // console.log(this.data.slice().sort(this.compare));

      return this.$store.state.meldungen;
    },

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">


</style>
