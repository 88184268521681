<template>

  <div class="">

    <div class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">

      <div @click="notfall()" class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>

      <div class="modal-container bg-white w-11/12 md:max-w-lg mx-auto rounded shadow-lg z-50 overflow-y-auto  ">

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="modal-content py-4 text-left px-6">
          <!--Title-->
          <div class="flex justify-between items-center pb-3">
            <p class="text-2xl font-bold text-red-600">Notfall</p>
            <div @click="notfall()" class="modal-close cursor-pointer z-50">
              <svg class="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
              </svg>
            </div>
          </div>

          <p>Bei dringenden Notfällen oder aussserhalb unserer Praxisöffnungszeiten:</p>

          <br>

          <table style="width: 100%;">
            <tbody>
              <tr>
                <td>Sanitätspolizei</td>
                <td>144</td>
              </tr>
              <tr>
                <td> <a class="text-red-600 hover:underline" href="https://www.medphone.ch/home" target="_blank"> Ärztenotruf Bern MEDPHONE </a></td>
                <td>0900 57 67 47</td>
              </tr>
              <tr>
                <td> <a class="text-red-600 hover:underline" href="https://www.localmed.ch/standorte/bern-postparc/city-notfall/"  target="_blank"> City Notfall Bern </a></td>
                <td>031 326 20 00</td>
              </tr>
              <tr>
                <td> <a class="text-red-600 hover:underline" href="https://www.hirslanden.ch/de/klinik-permanence/centers/notfallstation-klinikpermanence.html"  target="_blank"> Permanence </a></td>
                <td>031 990 41 11</td>
              </tr>
              <tr>
                <td> <a class="text-red-600 hover:underline" href="https://www.insel.ch/de/notfall/notfalldienste-inselspital/"  target="_blank"> Notfallstationen Inselspital </a> </td>
                <td> <a class="text-red-600 hover:underline" href="https://www.insel.ch/de/notfall/notfalldienste-inselspital/"  target="_blank"> Nummern anzeigen </a> </td>
              </tr>
            </tbody>

            <br>

          </table>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <!-- <button class="px-4 bg-transparent p-3 rounded-lg text-indigo-500 hover:bg-gray-100 hover:text-indigo-400 mr-2">Action</button> -->
            <!-- <button @click="notfall()" class="modal-close px-4 bg-red-600 p-3 rounded-lg text-white hover:bg-red-600">schliessen</button> -->
          </div>

        </div>
      </div>
    </div>

  </div>




</div>

</template>

<script>
export default {
  name: 'Notfall',
  props: {

  },
  data() {
    return {

    }
  },
  methods: {

    notfall(){

      // console.log("help");
      this.$store.commit('notfall');
    }

  },
  mounted: function(){
    // this.fetchContent();
  },

  computed: {

    sortedData() {
      // console.log(this.data.slice().sort(this.compare));
      return this.data.slice().sort(this.compare);
    },

  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>



</style>
