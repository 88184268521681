import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    doctors: [] ,
    notfall: false,
  },

  mutations: {

    notfall(state){

      state.notfall = !state.notfall;

    }

  },
  actions: {

  }
})
