<template>
  <div class="footervue bg-blue-200">

    <div class="flex flex-wrap text-left text-gray-800 mx-auto w-full lg:w-3/4">

      <div class="w-full md:w-1/2 p-2 px-8">

        <h2>Anfahrt</h2>

        <i class="fas fa-subway"></i>
        <p class="text-base mb-4">  Mit der S-Bahn bis Bahnhof Bümpliz Nord, mit dem Tram Nr. 7 bis Bümpliz Post oder mit dem Tram Nr. 8 bis Bethlehem Säge. Anschliessender Fussweg von ungefähr drei Minuten. Sie finden uns im 1. Stock an der Mühledorfstrasse 1.</p>

        <i class="fas fa-car"></i>
        <p class="text-base mb-4"> Besucherparkplätze befinden sich hinter dem Haus an der Mühledorfstrasse 1, vis à vis des Eingangs zum Ärztezentrum. Sie finden uns anschliessend im 1. Stock.</p>

        <i class="fas fa-wheelchair"></i>
        <p class="text-base mb-4"> Das gesamte Ärztezentrum ist rollstuhlgängig.</p>

      </div>

      <div class="w-full md:w-1/2 p-2 px-8">

        <h2>Karte</h2>

        <iframe class="w-full" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2723.8677390701496!2d7.39031397030413!3d46.944638515863474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478e39231fe55555%3A0x5ea8c99d1be3f580!2sAerztezentrum%20Fellergut!5e0!3m2!1sde!2sch!4v1567947150334!5m2!1sde!2sch" frameborder="0" style="border:0;" allowfullscreen=""></iframe>

        <!-- <h2 class="mt-6">Adresse</h2>

        <p class="text-base mb-4">Ärztezentrum Fellergut <br>

        1. Stock <br>

        Mühledorfsrasse 1 <br>

        3018 Bern</p> -->


        <div class="sm:flex mt-4">


          <div class="w-full sm:w-1/2">

            <h2>Adresse</h2>

            <p class="text-base mb-4">Ärztezentrum Fellergut <br>

              1. Stock <br>

              Mühledorfsrasse 1 <br>

              3018 Bern</p>

            </div>

            <div class="w-full sm:w-1/2">

              <h2>Links</h2>

              <a class="mt-8 text-blue-500 cursor-pointer hover:underline" href="https://www.ärztezentrum.ch/api/wp-login.php/" target="_blank"> Anmelden </a>

              <br>

              <router-link tag="a" to="/impressum" class="mt-8 text-blue-500 cursor-pointer hover:underline">Impressum</router-link>

              <br>

              <a class="mt-8 text-blue-500 cursor-pointer hover:underline" href="https://www.nickschnee.ch" target="_blank"> nickschnee.ch </a>

            </div>

          </div>

      </div>




      </div>

    </div>
  </template>

  <script>
  export default {
    name: 'FooterVue',
    props: {

    },
    data() {
      return {
      }
    },
    methods: {

    },
    mounted: function(){

    },

    computed: {


    },
  }

  </script>

  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>


  </style>
