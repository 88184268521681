<template>
  <div class="navigation font-heading">

    <nav class="flex items-center justify-between flex-wrap bg-blue-100 p-6">

      <div class="flex items-center flex-shrink-0 text-white mr-6">

        <img src="../assets/logo_light.png" alt="" width="60px" class="mr-6">

        <router-link tag="div" to="/" class="md:hidden font-bold font-heading text-header cursor-pointer">Ärztezentrum  <br> Fellergut</router-link>
        <router-link tag="div" to="/" class="hidden md:block font-bold font-heading text-4xl cursor-pointer">Ärztezentrum Fellergut</router-link>


      </div>

      <div class="block mx-auto mt-4 md:hidden">
        <button @click="showmenu = !showmenu" class="flex items-center px-3 py-2 border rounded text-white text-2xl">
          <i class="fas fa-bars"> </i>
        </button>
      </div>

      <!-- DESKTOP MENU -->
      <div class="hidden w-full md:block flex-grow md:flex md:items-center md:w-auto" >

        <div class="text-xl text-right md:flex-grow font-heading">
          <!-- <a href="" class="block mt-4 md:inline-block md:mt-0 text-white hover:text-white mr-4">
          Übersicht
        </a> -->

        <router-link :to="'/'" class="block mt-4 md:inline-block md:mt-0 text-white hover:text-white mr-4" :class="{currentnav: this.$router.currentRoute.name == 'home'}">

          Home

        </router-link>

        <!-- PRAXEN DROPDOWN START-->

        <router-link :to="'/praxis'" class="dropdown relative block cursor-pointer mt-4 md:inline-block md:mt-0 text-white hover:text-white mr-4" :class="{currentnav: this.$router.currentRoute.name == 'praxen' || this.$router.currentRoute.name == 'praxis'}">
          <span>Praxen</span>

        <div class="dropdown-content shadow text-left p-4 bg-white w-1/3-vw border border-blue-500">

          <h2 class=" mb-2" v-if="hausarzt.length > 0"> Hausärztinnen und Hausärzte</h2>

          <router-link v-if="index.url" tag="div" :to="'../praxis/' + index.url" class="" v-for="index in sortedHausarzt" >

            <p class="url-nav text-red-500 hover:underline mb-2 ml-8"> {{index.anrede}} {{index.name}} {{index.lastname}} </p>

          </router-link>

          <h2 class=" mb-2" v-if="kinderarzt.length > 0"> Kinderarzt</h2>

          <router-link v-if="index.url" tag="div" :to="'../praxis/' + index.url" class="" v-for="index in sortedKinderarzt" >

            <p class="url-nav hover:underline mb-2 ml-8"> {{index.anrede}} {{index.name}} {{index.lastname}} </p>

          </router-link>

          <h2 class=" mb-2" v-if="physio.length > 0"> Physiotherapie</h2>

          <router-link v-if="index.url" tag="div" :to="'../praxis/' + index.url" class="" v-for="index in sortedPhysio" >

            <p class="url-nav hover:underline mb-2 ml-8"> {{index.anrede}} {{index.name}} {{index.lastname}} </p>

          </router-link>

          <h2 class=" mb-2" v-if="psychotherapie.length > 0"> Psychotherapie</h2>

          <router-link v-if="index.url" tag="div" :to="'../praxis/' + index.url" class="" v-for="index in sortedPsychotherapie" >

            <p class="url-nav hover:underline mb-2 ml-8"> {{index.anrede}} {{index.name}} {{index.lastname}} </p>

          </router-link>

          <h2 class=" mb-2" v-if="magendarm.length > 0"> Magendarm Zentrum</h2>

          <router-link v-if="index.url" tag="div" :to="'../praxis/' + index.url" class="" v-for="index in sortedMagendarm" >

            <p class="url-nav hover:underline mb-2 ml-8"> {{index.anrede}} {{index.name}} {{index.lastname}} </p>

          </router-link>

          <h2 class=" mb-2" v-if="ernährung.length > 0"> Ernährungsberatung</h2>

          <router-link v-if="index.url" tag="div" :to="'../praxis/' + index.url" class="" v-for="index in sortedErnährung" >

            <p class="url-nav hover:underline mb-2 ml-8"> {{index.anrede}} {{index.name}} {{index.lastname}} </p>

          </router-link>

          <h2 class=" mb-2" v-if="labor.length > 0"> Labor</h2>

          <router-link v-if="index.url" tag="div" :to="'../praxis/' + index.url" class="" v-for="index in sortedLabor" >

            <p class="url-nav hover:underline mb-2 ml-8"> {{index.anrede}} {{index.name}} {{index.lastname}} </p>

          </router-link>

        </div>

        <!-- router link -->
      </router-link>

        <router-link :to="'../aktuelles'" class=" text-white hover:text-white mr-4" :class="{currentnav: this.$router.currentRoute.name == 'aktuelles'}">

          Über uns

        </router-link>

        <!-- PRAXEN DROPDOWN ENDE-->

        <!-- <a href="" class="block mt-4 md:inline-block md:mt-0 text-white hover:text-white mr-4">
        Angebot
      </a>

      <a href="" class="block mt-4 md:inline-block md:mt-0 text-white hover:text-white">
      Anfahrt
    </a> -->

  </div>

</div>

</nav>


<!-- Mobile Menu -->
<div v-if="showmenu" class="bg-blue-100 z-10 fixed w-full h-full top-0 left-0 opacity-95">

  <div class="text-white text-center text-4xl mb-4 mt-4">
    <i @click="showmenu = !showmenu" class="fas fa-times cursor-pointer"></i>
  </div>

  <router-link tag="div" :to="'/'" class="text-white text-center text-4xl mb-4 cursor-pointer">
    <p> <span class="text-white text-center text-4xl mb-4" :class="{currentnav: this.$router.currentRoute.name == 'home'}">Übersicht</span> </p>
  </router-link>

  <router-link tag="div" :to="'/praxis'" replace class="text-white text-center text-4xl mb-4 cursor-pointer">
    <p> <span class="text-white text-center text-4xl mb-4" :class="{currentnav: this.$router.currentRoute.name == 'praxis'}">Praxen</span> </p>
  </router-link>

  <router-link tag="div" :to="'aktuelles'" class="text-white text-center text-4xl mb-4 cursor-pointer">
    <p> <span class="text-white text-center text-4xl mb-4" :class="{currentnav: this.$router.currentRoute.name == 'aktuelles'}">Über uns</span> </p>
  </router-link>

  <!-- <div class="text-white text-center text-4xl mb-4">
  Praxen
</div>

<div class="text-white text-center text-4xl mb-4">
Lage
</div> -->

</div>


<!-- Desktop Menu -->




</div>

</template>

<script>
export default {
  name: 'Navigation',
  props: {

  },
  data() {
    return {
      hausarzt: [],
      kinderarzt: [],
      physio:[],
      psychotherapie:[],
      magendarm: [],
      ernährung: [],
      labor: [],
      showmenu: false,
    }
  },
  methods: {

    getroute(){
      // console.log(this.$router.currentRoute.name);
    },

    fetchContent(){

      fetch('https://ärztezentrum.ch/api/wp-json/acf/v3/mitarbeitende?filter[meta_key]=rolle&filter[meta_value]=Hausärztin/Hausarzt')  .then((result) => {
        return result.json();
      }).then((data) => {

        var i;
        for (i in data) {
          this.hausarzt.push(data[i].acf);
        }

        // console.log(this.hausarzt[0].name);

      });

      fetch('https://ärztezentrum.ch/api/wp-json/acf/v3/mitarbeitende?filter[meta_key]=rolle&filter[meta_value]=Kinderärztin/Kinderarzt')  .then((result) => {
        return result.json();
      }).then((data) => {

        var i;
        for (i in data) {
          this.kinderarzt.push(data[i].acf);
        }

        // console.log(this.hausarzt[0].name);

      });

      fetch('https://ärztezentrum.ch/api/wp-json/acf/v3/mitarbeitende?filter[meta_key]=rolle&filter[meta_value]=Physiotherapie')  .then((result) => {
        return result.json();
      }).then((data) => {

        var i;
        for (i in data) {
          this.physio.push(data[i].acf);
        }

      });

      fetch('https://ärztezentrum.ch/api/wp-json/acf/v3/mitarbeitende?filter[meta_key]=rolle&filter[meta_value]=Psychotherapie')  .then((result) => {
        return result.json();
      }).then((data) => {

        var i;
        for (i in data) {
          this.psychotherapie.push(data[i].acf);
        }

      });

      fetch('https://ärztezentrum.ch/api/wp-json/acf/v3/mitarbeitende?filter[meta_key]=rolle&filter[meta_value]=Magendarm-Praxis')  .then((result) => {
        return result.json();
      }).then((data) => {

        var i;
        for (i in data) {
          this.magendarm.push(data[i].acf);
        }

      });

      fetch('https://ärztezentrum.ch/api/wp-json/acf/v3/mitarbeitende?filter[meta_key]=rolle&filter[meta_value]=Ernährungsberatung')  .then((result) => {
        return result.json();
      }).then((data) => {

        var i;
        for (i in data) {
          this.ernährung.push(data[i].acf);
        }

      });

      fetch('https://ärztezentrum.ch/api/wp-json/acf/v3/mitarbeitende?filter[meta_key]=rolle&filter[meta_value]=Labor')  .then((result) => {
        return result.json();
      }).then((data) => {

        var i;
        for (i in data) {
          this.labor.push(data[i].acf);
        }

      });

    },

    compare(a, b) {
      if (a.lastname < b.lastname) {
        return -1;
      }
      if (a.lastname > b.lastname) {
        return 1;
      }
      // a muss gleich b sein
      return 0;
    },

  },
  mounted: function(){
    this.fetchContent();
    this.getroute();
  },

  computed: {

    sortedHausarzt() {
      // console.log(this.data.slice().sort(this.compare));
      return this.hausarzt.slice().sort(this.compare);
    },

    sortedKinderarzt() {
      // console.log(this.data.slice().sort(this.compare));
      return this.kinderarzt.slice().sort(this.compare);
    },

    sortedPhysio() {
      // console.log(this.data.slice().sort(this.compare));
      return this.physio.slice().sort(this.compare);
    },

    sortedPsychotherapie() {
      // console.log(this.data.slice().sort(this.compare));
      return this.psychotherapie.slice().sort(this.compare);
    },

    sortedMagendarm() {
      // console.log(this.data.slice().sort(this.compare));
      return this.magendarm.slice().sort(this.compare);
    },

    sortedErnährung() {
      // console.log(this.data.slice().sort(this.compare));
      return this.ernährung.slice().sort(this.compare);
    },

    sortedLabor() {
      // console.log(this.data.slice().sort(this.compare));
      return this.labor.slice().sort(this.compare);
    },

  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.dropdown-content {
  display: none;
  right: 0;
  position: absolute;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

</style>
