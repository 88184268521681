<template>
  <div class="overview">

    <div class="sm:flex m-4 mt-10">

      <div class="overflow-hidden w-full sm:w-1/2 sm:mr-2 mb-4">

        <img class="w-full " src="https://ärztezentrum.ch/api/wp-content/uploads/2019/11/header_melideo_small.jpg" alt="Keyvisual Ärztezentrum">

        <div class="p-4">

          <h1 class="">Das sind wir</h1>

          <p class="text-black mb-4">
            Das Ärztezentrum Fellergut ist eine Praxisgemeinschaft mit fünf Hausarztpraxen, einer Kinderarztpraxis, einer Augenarztpraxis, einer Psychotherapiepraxis, einer Physiotherapiepraxis und einer Magendarmpraxis.
          </p>

          <p v-if="meetus" class="text-black mb-4"> Unsere Ärztinnen, Ärzte und medizinische Praxisassistentinnen profitieren von regelmässigen internen und externen Fortbildungen. Zudem sind wir Mitglieder in Hausärztenetzwerken und verfügen über Qualitätssicherungszertifizierungen. Wir arbeiten eng mit lokalen Spitalärztinnen und Ärzten zusammen.</p>

          <router-link tag="p" to="/aktuelles" class="mt-4 text-xl text-blue-500 cursor-pointer hover:underline">Mehr über uns erfahren</router-link>

          <!-- <p v-if="!meetus" class="mt-4 text-xl text-blue-500 cursor-pointer hover:underline" @click="changeStatus">Mehr über uns erfahren</p> -->

          <!-- <p v-if="meetus" class="mt-4 text-xl text-blue-500 cursor-pointer hover:underline" @click="changeStatus">weniger anzeigen</p> -->

        </div>

      </div>

      <div class="overflow-hidden  w-full sm:w-1/2 sm:ml-2 mb-4">

        <img class="w-full" src="https://ärztezentrum.ch/api/wp-content/uploads/2019/11/bild_labor_melideo-2.jpg" alt="Keyvisual Ärztezentrum">

        <div class="p-4">

          <h1 class="">Angebot</h1>

          <p class="text-black mb-4">
            In unserem Ärztezentrum bieten wir verschiedene Dienstleistungen für die medizinische Grundversorgung an.
          </p>


          <Angebot> </Angebot>


          <!-- <p v-if="!offer" class="mt-4 text-xl text-blue-500 cursor-pointer" @click="changeOffer">Erkunden Sie unser Angebot</p>

          <p v-if="offer" class="mt-4 text-xl text-blue-500 cursor-pointer" @click="changeOffer">weniger anzeigen</p> -->


        </div>

      </div>

    </div>


  </div>

</template>

<script>
import Angebot from '@/components/Angebot.vue'

export default {
  name: 'Overview',
  components: {
    Angebot
  },
  props: {

  },
  data() {
    return {
      data: [],
      showmenu: false,
      meetus: false,
      offer: false,
    }
  },
  methods: {

    changeStatus: function () {
      this.meetus = !this.meetus;
    },

    changeOffer: function () {
      this.offer = !this.offer;
    },

  },

  computed: {

  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
