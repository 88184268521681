<template>
  <div class="gridpraxen">


    <h1 class="w-full md:w-1/2 border-blue-100 px-6 rounded mt-8">Praxen</h1>

    <div v-if="sortedData.length == 0" class="mx-auto my-20">

      <p class="text-center"><i class="fas fa-spin text-blue-500 fa-stethoscope spinner-big"></i> </p>

    </div>

    <div class="flex flex-wrap m-2">

      <!-- <div v-for="index in sortedData" class="p-2 flex flex-wrap rounded overflow-hidden outline w-full sm:w-1/2 md:w-1/3"> -->

      <div v-if="!index.hide" v-for="index in sortedData" class="card p-2 flex flex-wrap rounded outline w-full sm:w-1/2 md:w-1/3">


          <router-link tag="div" :to="'praxis/' + index.url" class="cursor-pointer">

            <div class="">

              <div class="container">

                <img v-if="index.keyvisual" class="image border-2 border-b-0 border-blue-500" :src="index.keyvisual.sizes.medium_large" alt="Keyvisual Praxis">
                <img v-else class="image" src="../assets/keyvisual_placeholder_logo.png" alt="Keyvisual Praxis">

                <div class="middle bg-white">
                  <div class="text text-blue-500 border border-blue-500">Praxis ansehen </div>
                </div>
              </div>

              <!-- <img class="" :src="index.keyvisual.sizes.medium_large" alt="Keyvisual Praxis"> -->

            </div>

            <div class="px-4">

              <h2 class="my-4" v-html="index.name"> </h2>

              <p class="text-black text-base mb-2"> {{index.shortoffer}} </p>

              <p class="mt-auto text-black text-base mb-6">Tel. {{index.phone}} </p>

              <!-- <router-link tag="div" :to="'praxis/'+ index.url" class=" mb-4 text-blue-100  btn-blue mx-auto w-3/4 text-center rounded p-2"> <span class="mx-auto"> Praxis besuchen </span> </router-link> -->

            </div>

          </router-link>

      </div>

    </div>


  </div>
</template>

<script>
export default {
  name: 'GridPraxen',
  props: {

  },
  data() {
    return {
      data: [],
      accordeon: [],
    }
  },
  methods: {

    fetchContent(){

      fetch('https://ärztezentrum.ch/api/wp-json/acf/v3/praxis/?per_page=100&filter[orderby]=name&order=asc')  .then((result) => {
        return result.json();
      }).then((data) => {

        var i;
        for (i in data) {
          this.data.push(data[i].acf);
        }

        // console.log(this.data);

      });

    },

    compare(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      // a muss gleich b sein
      return 0;
    },

  },
  mounted: function(){
    this.fetchContent();
  },

  computed: {

    sortedData() {
      // console.log(this.data.slice().sort(this.compare));
      return this.data.slice().sort(this.compare);
    },

  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.border-box{
  box-sizing: border-box !important;
}

.mt-auto {
  margin-top: auto;
}

.image-auto {
  width: 100%;
  height: auto;
}

.spinner-big{
  font-size: 5rem;
  text-align: center !important;
}

.container {
  position: relative;
}

.image {
  opacity: 1;
  /* display: block; */
  transition: .5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.card:hover .image {
  opacity: 0.3;
}

.card:hover .middle {
  opacity: 1;
}

.text {
  font-size: 16px;
  padding: 16px 32px;
}




</style>
